/* -Base for all icons
-----------------------------------------------------------------------------*/
// Icon dimensions are generated in _icons.scss

.icon {
  display: inline-block;
  vertical-align: middle;

  svg {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: top;
    fill: currentColor;
  }
}
