// -------------------- Color definitions -------------------- //
$color-gray-darkest: #1b1b1b;
$color-gray-dark: #39393b;
$color-blue: #4a90e2;
$color-live-red: #ce2e49;

$color-brand-magenta: #de007b;
$color-brand-orange: #ec6b10;
$color-brand-magenta-orange-mix: mix($color-brand-magenta, $color-brand-orange);
$brand-gradient: linear-gradient(
  to right,
  $color-brand-magenta,
  $color-brand-orange
);
$brand-gradient-darkened: linear-gradient(
  to right,
  darken($color-brand-magenta, 10%),
  darken($color-brand-orange, 10%)
);
$brand-gradient-diagonal-transparent: linear-gradient(
  to bottom right,
  rgba($color-brand-magenta, 0.8),
  rgba($color-brand-orange, 0.8)
);

// Darkest gray text color on black that meets the WCAG AA accessibility
// requirement. (Approximately #757575 on #000.)
$color-darkest-accessible-on-dark: rgba(255, 255, 255, 0.46);
// Darkest gray text color on black that meets the WCAG AA accessibility
// requirement for LARGE TEXT ONLY. Large is defined as 19px+ and bold,
// or 24px+. (Approximately #5c5c5c on #000.)
$color-darkest-accessible-on-dark-large: rgba(255, 255, 255, 0.36);

// -------------------- Semantic assignments -------------------- //
// When possible and logical, use colors through more meaningful
// "use case" names.

// Main body colors
$color-body-foreground: #fff;
$color-body-background: $color-gray-darkest;

// Form fields
$color-form-field-foreground: $color-gray-dark;
$color-form-field-background: #fff;

// Brand things like selection color
$color-main: $color-brand-magenta;

$color-stream-bar-background: $color-gray-dark;

// Borders and such
$color-separator: rgba(255, 255, 255, 0.2);

// Links and main buttons
$color-action: $color-blue;
$color-action-foreground: #fff;

// Forms
$color-form-field-border: #ccc;

// Status
$color-error: #ed404b;
$color-error-foreground: #ed404b;
$color-error-background: #fae8e6;
$color-success: #49ad5a;
$color-success-foreground: #337a3b;
$color-success-background: #e7fae6;
