/* -Blurb component - block of image, heading, short text and a link
-----------------------------------------------------------------------------*/
.blurb-list-wrap--cols-3 {
  @include wrap-width-wide;
}

.blurb {
  display: flex;
  flex-direction: column;
}

.blurb__inner {
  @include rounded('large');

  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
  min-height: 275px;
  margin: 0 auto;
  padding: 20px 25px;
  overflow: hidden;
  background-size: cover;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

  @include media-min(rem(800px)) {
    font-size: rem(18px);
  }
  .blurb--image-only & {
    align-items: center;
    justify-content: center;
    padding: 0;
    min-height: 0;
  }
  .blurb--linked & {
    transition: opacity 0.15s ease;

    &:hover {
      opacity: 0.75;
    }
  }
}
// The block padding is visually balanced for text due to line-height, but
// buttons and images go edge to edge and makes the vertical padding look
// too small. Add a bit for those specifically to compensate.
.blurb__image:not(:last-child) {
  margin-top: 5px;
}
.blurb__link .link-block--button {
  padding-bottom: 5px;
}

.blurb__image {
  // Prevent stretching
  align-self: center;
}

.blurb__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .blurb__image + & {
    margin-top: 1em;
  }
}
.blurb__title {
  margin-bottom: 10px;
}
.blurb__text {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.blurb__link {
  margin-top: auto;
  padding-top: 1.5em;
  text-align: right;
}

.overlay-link {
  &:hover,
  &:focus {
    ~ .blurb__link a {
      text-decoration: none;
    }
  }
}

/*--------------- List of blurbs (blurbs block) ---------------*/
.blurb-list-wrap {
  @include section-margin(2);

  // Halve the section margin for blurbs that come directly after an intro
  // with content.
  .intro--has-content + & {
    @include section-margin-top;
  }
}

.blurb-list {
  @include grid-container;
  @include grid-pull-bottom;

  .blurb {
    @include grid-item(1);
    @include grid-margin-bottom;
  }
}

@include media-min(rem(1000px)) {
  .blurb-list--cols-2,
  .blurb-list--cols-3 {
    .blurb {
      @include grid-item-size(1 / 2);
    }
  }
}

@include media-min(rem(1400px)) {
  .blurb-list--cols-3 {
    .blurb {
      @include grid-item-size(1 / 3);
    }
  }
}
