$wrap-width: rem(1000px);
$wrap-width-thin: rem(780px);
$wrap-width-wide: rem(1600px);

$page-margin: rem(50px);
$page-margin-small: rem(20px);
$section-spacing: 80px;

// Consistent border radius, consider using via the `rounded` mixin.
$border-radius: (
  small: 6px,
  medium: 10px,
  large: 20px,
);

// Form elements
// Using em instead of rem so that dimensions adapt to local font size changes
$form-control-base-height: 40px;
$form-control-font-size: 22px;
$form-control-radius: 10px;
$form-field-padding: em(8px, $form-control-font-size);
$form-control-height: em($form-control-base-height, $form-control-font-size);
$button-font-size: 22px;
// Used for buttons matching form fields
$button-height: em($form-control-base-height, $button-font-size);

$sectioning-title-margin: 15px;
$sectioning-title-padding: 10px;

$stream-bar-height: rem(40px);

$expo-list-item-spacing: 40px;
$expo-list-item-spacing-small: $expo-list-item-spacing / 2;

// Program schedule
$program-schedule-item-date-radius: 5px;

// Expo detail
$about-section-columns-breakpoint: rem(1300px);

/*-------------------- Common breakpoints --------------------*/
// Most breakpoints should be isolated to what they affect and not be some
// arbitrary values, but global changes like text size may require adjustment
// to details in some instances and should therefore be a defined variable.

// Tighten layout
$breakpoint-grid-tight: rem(700px);
$breakpoint-page-margin-small: rem(550px);

$breakpoint-larger-schedule-table: rem(750px);
