/* -Headings
-----------------------------------------------------------------------------*/
@mixin h {
  @include alt-font('bold');

  line-height: 1.25;
}
@mixin h1 {
  @include type-size('h1');

  text-transform: uppercase;
}
@mixin h2 {
  @include type-size('h2');

  text-transform: uppercase;
}
@mixin h3 {
  @include type-size('h3');

  text-transform: none;
}
@mixin h4 {
  @include type-size('h4');

  text-transform: none;
}
@mixin h5 {
  @include type-size('h5');

  text-transform: none;
}
@mixin h6 {
  @include type-size('h6');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include h;

  margin-bottom: 5px;

  a:link,
  a:visited {
    color: inherit;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    color: $color-action;
    text-decoration: underline;
  }
}
h1,
.h1 {
  @include h1;
}
h2,
.h2 {
  @include h2;
}
h3,
.h3 {
  @include h3;
}
h4,
.h4 {
  @include h4;
}
h5,
.h5 {
  @include h5;
}
h6,
.h6 {
  @include h6;
}

.h-upper {
  text-transform: uppercase;
}

.h-sectioning {
  color: $color-darkest-accessible-on-dark;
  margin-bottom: $sectioning-title-margin;
  padding-left: $sectioning-title-padding;
  padding-right: $sectioning-title-padding;
  border-bottom: 1px solid $color-separator;
}

.rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0 !important;
    }
  }
}
