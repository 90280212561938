/* -Flickity card slider
---------------------------------------------------------------------------- */
// Default to a scrolling div as a fallback.

.card-slider {
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-color: lighten($color-body-background, 20%)
    lighten($color-body-background, 5%);
  scrollbar-width: thin;
  padding: 0 0 20px;

  &.flickity-enabled {
    overflow-x: visible;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100px;
      pointer-events: none;
      z-index: 1;
    }
    &::before {
      @include easing-gradient('to right', $color-body-background);
      left: 0;
    }
    &::after {
      @include easing-gradient('to left', $color-body-background);
      right: 0;
    }
  }
  .flickity-prev-next-button {
    &.previous {
      left: auto;
      right: 100%;
    }
    &.next {
      right: auto;
      left: 100%;
    }
  }
}
.card-slider--at-start::before,
.card-slider--at-end::after {
  display: none !important;
}

.card-slider__item {
  // Using padding for spacing since margin doesn't affect it. The padding
  // shouldn't affect the width.
  box-sizing: content-box;
  display: inline-block;
  vertical-align: top;
  width: 300px;
  min-height: 100%;
  padding-right: $expo-list-item-spacing;
  white-space: normal;

  @include when-grid-tight {
    padding-right: $expo-list-item-spacing-small;
  }
  &:last-child {
    padding-right: 0;
  }
}

@include media-max(rem(400px)) {
  .card-slider__item {
    width: 78vw;
  }
}
