/* -Is live pulsating animation
---------------------------------------------------------------------------- */
$is-live-width: 0.7em;
$is-live-height: 0.7em;
$is-live-animation-speed: 2s;

@keyframes is-live-pulse {
  0% {
    background-color: lighten($color-live-red, 10%);
  }
  100% {
    background-color: darken($color-live-red, 10%);
  }
}

.live-now-icon {
  width: $is-live-width;
  height: $is-live-height;
  margin-right: 0.25em;
  background-color: $color-live-red;
  border-radius: 50%;
  display: inline-block;
  animation: is-live-pulse $is-live-animation-speed alternate infinite;
}
