/* -Team member - block of image, name, email and phone number
-----------------------------------------------------------------------------*/
// 'Global' class for use both inside and outside a .team-member block.
.team-member-avatar {
  max-width: 100px;
  border-radius: 50%;
  overflow: hidden;
}

$team-gutter: 30px;
$team-gutter-small: $team-gutter / 2;

// List of members
.team__list {
  @include grid-container($team-gutter, $team-gutter-small);
  @include grid-pull-bottom($team-gutter, $team-gutter-small);

  .team-member {
    @include grid-item(1, $team-gutter, $team-gutter-small);
    @include grid-margin-bottom($team-gutter, $team-gutter-small);
  }
}
@include media-min(rem(700px)) {
  .team__list .team-member {
    @include grid-item-size(1/2);
  }
}
@include media-min(rem(1000px)) {
  .team__list .team-member {
    @include grid-item-size(1/3);
  }
}
@include media-min($about-section-columns-breakpoint) {
  .team__list .team-member {
    @include grid-item-size(1/2);
  }
}

// Member
.team-member__inner {
  display: flex;
  align-items: center;
  height: 100%;
}
.team-member__image {
  align-self: flex-start;
  margin-right: 15px;
}
.team-member__content {
  font-size: rem(14px);

  p {
    margin: 0.25em 0 0;
  }
}
.team-member__name {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: $font-weight-bold;
}
