// Clear contained floats
@mixin clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
  &::after {
    clear: both;
  }
}

// Almost hide like display none but keep things like form fields for POSTing.
@mixin invisible {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
@mixin non-invisible {
  visibility: visible;
  height: auto;
}

// Screen reader accessible text, from HTML5 Boilerplate
@mixin visuallyhidden {
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
}
@mixin non-visuallyhidden {
  clip: auto;
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  white-space: inherit;
}

/// A color-to-transparent gradient without the sharp edge.
///
/// https://css-tricks.com/easing-linear-gradients/
/// https://github.com/larsenwork/postcss-easing-gradients
///
/// @param {string} $direction - Gradient direction.
/// @param {color} $color - Which color to start from.
@mixin easing-gradient($direction: 'to top', $color: #000) {
  $stops-config: (
    19: 0.74,
    34: 0.54,
    47: 0.38,
    56: 0.28,
    65: 0.19,
    73: 0.13,
    80: 0.07,
    86: 0.04,
    91: 0.02,
    95: 0.008,
    98: 0.002,
    100: 0,
  );
  $stops: ();
  @each $stop-point, $alpha in $stops-config {
    $stops: append(
      $stops,
      rgba($color, $alpha) percentage($stop-point / 100),
      $separator: comma
    );
  }
  background: linear-gradient(unquote($direction), $color 0%, $stops);
}

/// Consistent border radius.
///
/// @param {string} [$size] - Radius size name, "small", "medium" or "large".
@mixin rounded($size) {
  border-radius: map-get-strict($border-radius, $size);
}

// Consistent section and block spacing
@mixin section-margin($times: 1) {
  margin-top: $section-spacing * $times;
  margin-bottom: $section-spacing * $times;
}
@mixin section-margin-top($times: 1) {
  margin-top: $section-spacing * $times;
}
@mixin section-margin-bottom($times: 1) {
  margin-bottom: $section-spacing * $times;
}
@mixin section-pull-top($times: 1) {
  margin-top: -$section-spacing * $times;
}
@mixin section-pull-bottom($times: 1) {
  margin-bottom: -$section-spacing * $times;
}
@mixin section-padding($times: 1) {
  padding-top: $section-spacing * $times;
  padding-bottom: $section-spacing * $times;
}
@mixin section-padding-top($times: 1) {
  padding-top: $section-spacing * $times;
}
@mixin section-padding-bottom($times: 1) {
  padding-bottom: $section-spacing * $times;
}

// Media queries
// Defaults to width, since it's far more common than height.
@mixin media-max($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin media-min($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin media-between($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin media-max-height($max) {
  @media screen and (max-height: $max) {
    @content;
  }
}
@mixin media-min-height($min) {
  @media screen and (min-height: $min) {
    @content;
  }
}
@mixin media-between-height($min, $max) {
  @media screen and (min-height: $min) and (max-height: $max) {
    @content;
  }
}
