/* -'Image and content' block
-----------------------------------------------------------------------------*/
.image-content-block {
  @include section-margin(2);
}

.image-content-block__image {
  margin-bottom: 1.5em;

  img {
    @include rounded('large');
    overflow: hidden;
  }
}

@include media-min(rem(900px)) {
  .image-content-block__inner {
    @include grid-container;

    .image-content-block:nth-child(2n) & {
      flex-direction: row-reverse;
    }
  }
  .image-content-block__image,
  .image-content-block__content {
    @include grid-item(1 / 2);
    margin-bottom: 0;
  }
}

@include media-min(rem(1200px)) {
  .image-content-block__inner {
    @include grid-align-center;
  }
}
