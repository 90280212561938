/* -Buttons
-----------------------------------------------------------------------------*/

/*---------- Mixins ----------*/
@mixin button-base {
  @include rounded('medium');
  @include alt-font('semi');

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px 8px;
  border: 0;
  font-size: rem($button-font-size);
  line-height: 1.2;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  text-shadow: none;
  vertical-align: middle;
  box-shadow: none;
  transition: background-color 0.15s ease-out;

  @include media-max(rem(500px)) {
    font-size: rem(16px);
  }
  &:focus {
    outline: 1px dotted $color-body-foreground;
  }
}
@mixin button-hover-focus {
  &:not([disabled]) {
    &:hover,
    &:focus {
      @content;
    }
  }
}

@mixin button-primary-base-colors($important: null) {
  background-color: $color-brand-magenta $important;
  background-image: $brand-gradient $important;
  color: #fff $important;
}
@mixin button-primary-active-colors($important: null) {
  background-color: darken($color-brand-magenta, 10%) $important;
  background-image: $brand-gradient-darkened $important;
}
@mixin button-primary-colors {
  @include button-primary-base-colors;

  @include button-hover-focus {
    @include button-primary-active-colors;
  }
}
@mixin button-primary {
  @include button-base;
  @include button-primary-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-secondary-base-colors($important: null) {
  background: #fff $important;
  color: $color-gray-darkest $important;
}
@mixin button-secondary-active-colors($important: null) {
  background: darken(#fff, 15%) $important;
}
@mixin button-secondary-colors {
  @include button-secondary-base-colors($important: !important);

  @include button-hover-focus {
    @include button-secondary-active-colors($important: !important);
  }
}
@mixin button-secondary {
  @include button-base;
  @include button-secondary-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-faded-base-colors($important: null) {
  border: 1px solid $color-separator;
  background: $color-body-background $important;
  color: rgba(255, 255, 255, 0.6) $important;
}
@mixin button-faded-active-colors($important: null) {
  background: darken($color-body-background, 15%) $important;
}
@mixin button-faded-colors {
  @include button-faded-base-colors($important: !important);

  @include button-hover-focus {
    @include button-faded-active-colors($important: !important);
  }
}
@mixin button-faded {
  @include button-base;
  @include button-faded-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-field-inline {
  padding-top: 0;
  padding-bottom: 0;
  height: $button-height;
  line-height: $button-height - em(2px, $button-font-size);
}

@mixin button-tiny {
  @include rounded('small');
  padding: 5px 15px;
  font-size: rem(14px);
  font-family: $font-stack-main;
  font-weight: $font-weight-regular;
  letter-spacing: 1px;
}

@mixin button-small {
  @include rounded('small');
  padding: 6px 12px 5px;
  font-size: rem($button-font-size - 2px);
}

@mixin button-mega {
  padding: 20px 30px 18px;
}

/*---------- Standard button classes ----------*/
.btn,
a.btn,
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  @include button-primary;

  &[disabled] {
    cursor: default;
  }
  > span {
    vertical-align: middle;
  }
  .icon,
  .spinner {
    flex-shrink: 0;

    &:first-child {
      margin-right: 0.5em;
    }
    &:last-child {
      margin-left: 0.5em;
    }
    &:only-child {
      margin-left: 0;
      margin-right: 0;
      vertical-align: top;
    }
  }
}

a.btn-secondary,
.btn-secondary {
  @include button-secondary;
}

a.btn-faded,
.btn-faded {
  @include button-faded;
}

// Next to a form field and should match in height.
a.btn--field-inline,
.btn--field-inline {
  @include button-field-inline;
}

a.btn--tiny,
.btn--tiny {
  @include button-tiny;
}

a.btn--small,
.btn--small {
  @include button-small;
}

a.btn--mega,
.btn--mega {
  @include button-mega;
}

// ----- Special use case buttons -----

// Semantically a button but doesn't have any styles to indicate it.
@mixin button-neutral {
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: transparent !important;
  color: inherit !important;
}
// .button-neutral {
//   @include button-neutral;
//
//   .no-js & {
//     cursor: default;
//   }
// }
