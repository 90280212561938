/* -Full program schedule
-----------------------------------------------------------------------------*/
.full-program-schedule {
  p {
    margin-bottom: 0;
  }
  .program-schedule-item ~ .program-schedule-item {
    margin-top: $grid-gutter;
  }
}

.block-program-events {
  @include page-margin;
}

@include media-min(rem(1200px)) {
  .full-program-schedule {
    @include grid-container;

    .program-schedule-item {
      @include grid-item(1 / 2);

      margin-top: 0 !important;
    }
  }
}

/* -Program schedule item
-----------------------------------------------------------------------------*/
.program-schedule-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;

  [role='tablist'] {
    margin-bottom: 0;
  }
}

.program-schedule-item__title {
  margin-bottom: 0;

  > span:first-child {
    margin-right: 15px;
  }
}

.program-schedule-item__description {
  margin: 0.5rem 0 1rem;
  font-size: rem(13px);
}

.program-schedule-item__day {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 1rem 10px;
    border-top: 1px solid $color-separator;

    @include media-max($breakpoint-larger-schedule-table - rem(1px)) {
      padding: 0.75rem 8px;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

/* -Program event row
-----------------------------------------------------------------------------*/
.program-event-row {
  font-size: rem(13px);
}

.program-event-row__time {
  width: 1em;
  padding-right: 10px;
  white-space: nowrap;

  .program-schedule-item--multi-stream & {
    text-align: center;
  }
  .stream-button {
    min-width: 75%;
    margin-top: 2px;
  }
}

.program-event-row__content {
  .live-now {
    margin-left: 10px;
    color: $color-darkest-accessible-on-dark;
  }
}

.program-event-row__link {
  padding: 0 5px 0 10px !important;
  text-align: right;

  a {
    display: inline-flex;
    align-items: center;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    text-decoration: none;
    white-space: nowrap;

    .text {
      display: inline-block;
      min-width: 5rem;
      max-width: 7rem;
      white-space: normal;
    }
    &:hover,
    &:focus {
      .text {
        text-decoration: underline;
      }
    }
  }
}

.program-event-row--passed {
  color: $color-darkest-accessible-on-dark;
}
.program-event-row--highlight {
  th,
  td {
    background-color: rgb(255, 255, 255, 0.1);
  }
  .program-event-row__title {
    font-weight: $font-weight-bold;
  }
}

.program-schedule-item__update {
  text-align: right;
}

@include media-min($breakpoint-larger-schedule-table) {
  .program-event-row__title {
    font-size: 1rem;
    font-weight: $font-weight-semi;
  }
  .program-event-row__link {
    a .text {
      min-width: 7rem;
      max-width: 12rem;
    }
  }
  .program-event-row__link--short {
    a .text {
      min-width: 0;
      white-space: nowrap;
    }
  }
}
