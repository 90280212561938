/* -Bouncing 'sound bars' animation
---------------------------------------------------------------------------- */
$stream-playing-width: 3px;
$stream-playing-base-height: 2px;
$stream-playing-height: 10px;
$stream-playing-spacing: 2px;
$stream-playing-base-speed: 0.18s;

@keyframes currently-playing-bounce {
  from {
    transform: translateY($stream-playing-height - $stream-playing-base-height);
  }
  to {
    transform: translateY(0);
  }
}

.currently-playing-icon {
  position: relative;
  display: inline-block;
  width: $stream-playing-width * 3 + $stream-playing-spacing * 2;
  height: $stream-playing-height;
  overflow: hidden;
  margin-right: 10px;

  &::before,
  > span,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    width: $stream-playing-width;
    height: $stream-playing-height;
    transform: translateY($stream-playing-height - $stream-playing-base-height);
  }
  &::before {
    left: 0;
    background: $color-brand-magenta;
  }
  > span {
    left: $stream-playing-width + $stream-playing-spacing;
    background: $color-brand-magenta-orange-mix;
  }
  &::after {
    left: $stream-playing-width * 2 + $stream-playing-spacing * 2;
    background: $color-brand-orange;
  }
  .stream-playing & {
    &::before,
    > span,
    &::after {
      animation: currently-playing-bounce $stream-playing-base-speed ease-out
        alternate infinite;
    }
    > span {
      animation-duration: $stream-playing-base-speed - 0.05s;
    }
    &::after {
      animation-duration: $stream-playing-base-speed + 0.05s;
    }
  }
}
