// Neutraface Text Demi
@font-face {
  font-family: 'Neutraface Text';
  src: url('../fonts-local/neutraface-text-demi.woff2') format('woff2'),
    url('../fonts-local/neutraface-text-demi.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Neutraface Text Bold
@font-face {
  font-family: 'Neutraface Text';
  src: url('../fonts-local/neutraface-text-bold.woff2') format('woff2'),
    url('../fonts-local/neutraface-text-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
