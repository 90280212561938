/* AUTOGENERATED, DO NOT EDIT MANUALLY */

/* montserrat-latin-300-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('../fonts/montserrat-latin-300-normal.woff2') format('woff2'), url('../fonts/montserrat-latin-300-normal.woff') format('woff');
  
}

/* montserrat-latin-400-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/montserrat-latin-400-normal.woff2') format('woff2'), url('../fonts/montserrat-latin-400-normal.woff') format('woff');
  
}

/* montserrat-latin-700-normal*/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/montserrat-latin-700-normal.woff2') format('woff2'), url('../fonts/montserrat-latin-700-normal.woff') format('woff');
  
}

/* montserrat-latin-400-italic*/
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/montserrat-latin-400-italic.woff2') format('woff2'), url('../fonts/montserrat-latin-400-italic.woff') format('woff');
  
}
