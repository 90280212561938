/* - List of media items
-----------------------------------------------------------------------------*/
$media-item-gutter: 40px;
$media-item-gutter-small: $media-item-gutter / 2;

.media-items {
  @include grid-container($media-item-gutter, $media-item-gutter-small);
  @include grid-pull-bottom($media-item-gutter, $media-item-gutter-small);
}

.media-item {
  @include grid-item(1/2, $media-item-gutter, $media-item-gutter-small);
  @include grid-margin-bottom($media-item-gutter, $media-item-gutter-small);

  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 40px;

    @include media-min(rem(1000px)) {
      height: 60px;
      width: 60px;
    }
    @include media-min(rem(1400px)) {
      height: 80px;
      width: 80px;
    }
  }
}

@include media-min(rem(600px)) {
  .media-item {
    @include grid-item-size(1/3);
  }
}

@include media-min(rem(800px)) {
  .media-item {
    @include grid-item-size(1/4);
  }
}
