/* -Section with all partners
---------------------------------------------------------------------------- */
.partners-section {
  @include section-margin;
  @include section-padding-top;

  border-top: 1px solid $color-separator;
  text-align: center;
}

.partners__item {
  display: inline-block;
  max-width: 200px;

  img {
    max-height: 100px;
  }
}
.partners__item--main {
  max-width: 450px;

  img {
    max-height: 450px;
  }
}

/* ------------------- Specific for large/standalone ------------------- */
.partners-section {
  .partners__main {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .partners__organizers {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .partners__organizers-title {
    margin: 40px 0 15px;
  }
  .partners__item {
    vertical-align: middle;
    margin: 0 20px 20px;
  }
}

/* ------------------- Specific for stream overlay ------------------- */
.stream-overlay {
  .partners__organizers-title {
    margin: 40px 0 -5px;
  }
  .partners__item {
    margin-top: 20px;
  }
  .partners__regular,
  .partners__organizers {
    @include grid-container;

    .partners__item {
      @include grid-item(1 / 2);
    }
  }
  .partners__organizers--count-1 {
    .partners__item {
      @include grid-item(3 / 4);
    }
  }
}
