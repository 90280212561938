/* -Flickity base styles
---------------------------------------------------------------------------- */
// Adjusted version of the default Flickity CSS.
// Flickity v2.2.1 https://flickity.metafizzy.co

.flickity-enabled {
  position: relative;

  &:focus {
    outline: 0;
  }
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

// Draggable

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;

    &.is-pointer-down {
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
  }
}

/* ------------------- Buttons ------------------- */
.flickity-button {
  position: absolute;
  background: transparent !important;
  border: none;
  color: inherit;

  &:hover,
  &:focus {
    color: $color-main;
  }
  &:active {
    opacity: 0.6;
  }
  &:disabled {
    opacity: 0;
    cursor: auto;
    // Prevent disabled button from capturing pointer up event. Issue 716.
    pointer-events: none;
  }
}

.flickity-button-icon {
  fill: currentColor;
}

// Previous/next buttons
.flickity-prev-next-button {
  top: 50%;
  transform: translateY(-50%);
  width: $page-margin;
  height: 100px;
  padding: 0;
  border-radius: 0;

  @include when-page-margin-small {
    width: $page-margin-small;
  }
  &.previous {
    left: 0;
  }
  &.next {
    right: 0;
  }
  // Right to left
  // .flickity-rtl &.previous {
  //   left: auto;
  //   right: 10px;
  // }
  // .flickity-rtl &.next {
  //   right: auto;
  //   left: 10px;
  // }

  .flickity-button-icon {
    width: 20px;
    height: 20px;
  }
}

/* ------------------- Page dots ------------------- */
/* .flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;

  .flickity-rtl & {
    direction: rtl;
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;

    &.is-selected {
      opacity: 1;
    }
  }
} */

/* ------------------- flickity-fade ------------------- */
.flickity-enabled.is-fade {
  .flickity-slider > * {
    pointer-events: none;
    z-index: 0;
  }
  .flickity-slider > .is-selected {
    pointer-events: auto;
    z-index: 1;
  }
}
