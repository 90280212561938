// Height larger than zero so that the content isn't ignored by screen readers.
// Padding equal to the height to push any edge-touching content outside the
// box (the height stays the same due to border-box box-sizing). A negative
// top margin equal to the height to offset the space it takes, making the
// dimensions the same as it would be by just removing the content completely.
.hidden-with-width {
  height: 1px;
  padding-top: 1px;
  margin-top: -1px;
  overflow: hidden;
}
