/* -The main header area with logo and navigation
-----------------------------------------------------------------------------*/
.site-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 10px;

  .has-intro & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $z-header;
  }
  .js .has-intro.has-stream-overlay & {
    top: $stream-bar-height;
  }
}

.site-header-logo {
  max-width: 250px;

  a,
  img {
    display: block;
  }
  a {
    max-width: 300px;
  }
}

/* ------------------- Nav menus ------------------- */
.site-header-nav {
  margin-top: 10px;
  text-align: center;

  li {
    margin: 5px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  a,
  button {
    @include rounded('small');
    @include alt-font('semi');

    display: inline-block;
    padding: 5px 10px 3px;
    background: $color-body-background;
    vertical-align: middle;
    font-size: inherit;
    line-height: inherit;
    text-transform: uppercase;
    text-align: left;
    transition: background-color 0.1s ease;

    &:hover,
    &:focus {
      background: lighten($color-body-background, 10%);
    }
  }
  .current-item,
  .current-item-ancestor {
    > a {
      @include button-secondary-colors;
    }
  }
}

.menu-toggle {
  display: none !important;
}

@include media-min(rem(600px)) {
  .site-header {
    flex-direction: row;
    padding: 20px;
  }
  .site-header-logo {
    max-width: 40%;
  }
  .site-header-nav {
    margin-top: 0;
    margin-left: auto;
    text-align: right;

    li {
      margin: 5px 0 5px 10px;
    }
  }
}

@include media-min(rem(800px)) {
  .site-header {
    padding: 25px 30px;
  }
  .site-header-nav {
    a,
    button {
      @include rounded('medium');
      font-size: rem(22px);
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
