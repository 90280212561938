/* -Expo detail
-----------------------------------------------------------------------------*/
.expo-detail__twitch {
  @include section-margin-top(1.2);
  @include section-margin-bottom(2);

  text-align: center;
  font-size: rem(18px);
  color: $color-darkest-accessible-on-dark;

  .twitch-video {
    margin-top: 10px;
  }
}

/*---------- Tags ----------*/
.expo-detail__tags-section {
  @include section-margin;
}
.expo-detail__tags {
  margin: 0;
  list-style: none;

  li {
    display: inline-block;
    margin: 0 10px 10px 0;
  }
  .expo-detail__tags-section & {
    text-align: center;

    li {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

/*---------- Intro ----------*/
// Must have a wrapping column flex container for IE11
// https://github.com/philipwalton/flexbugs#flexbug-3
.expo-detail__intro-container {
  display: flex;
  flex-direction: column;
}

.expo-detail__intro {
  position: relative;
  display: flex;
  align-items: center;
  height: 40vh;
  max-height: 200px;

  @include media-min(rem(500px)) {
    max-height: 300px;
  }
  @include media-min(rem(900px)) {
    max-height: 400px;
  }
}

.intro-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.intro-content {
  z-index: 3;
  img {
    max-height: 200px;
  }
}

/*---------- Content ----------*/
.expo-detail {
  hr {
    margin-top: 15px;
    margin-bottom: 30px;
    color: $color-separator;
  }
}
.expo-detail__description {
  @include section-margin;
  @include lead-text;

  text-align: center;
}

.expo-detail__section-program-inner,
.expo-detail__program {
  @include section-margin;
}

.expo-detail__chat-members {
  margin-top: 20px;
  margin-bottom: 30px;

  .team-member-avatar {
    display: inline-block;
    width: 100px;
    max-width: 25%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

    &:not(:first-child) {
      margin-left: -15px;
    }
  }
}

.expo_detail__social-links {
  margin-top: 20px;
  columns: 2;
}
.expo-detail__social-link {
  margin-bottom: 20px;

  a {
    text-decoration: none;
    font-size: rem(14px);

    &:hover,
    &:focus {
      .text {
        text-decoration: underline;
      }
    }
  }
  .social-logo,
  .icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
}

.expo-detail__contact {
  .contact__title {
    margin-bottom: 20px;
  }
  .contact__item {
    margin-bottom: 10px;

    .text {
      margin-right: 20px;
      font-weight: $font-weight-bold;
      text-decoration: none;
    }
  }
}

.expo-detail__interest {
  .expo-detail__tags {
    margin-top: 20px;
  }
}
.expo-detail__interest,
.expo-detail__chat,
.expo-detail__follow-us {
  margin-bottom: $section-spacing / 2;
}

.expo-detail__trailer {
  @include section-margin;
}

.expo-detail__media {
  @include section-margin;
}
.expo-detail__about-inner {
  @include section-margin;
}
.about__content {
  @include section-margin-bottom;

  p {
    max-width: $wrap-width-thin;
  }
  .company-logo {
    max-width: 300px;
  }
}

.expo-detail__related {
  @include section-margin;
  @include section-padding-top;
  border-top: 1px solid $color-separator;
}
.expo-detail-slider {
  margin-top: 20px;
}

/*---------- Media queries ----------*/
@include media-min(rem(500px)) {
  .expo-detail__tags {
    li {
      margin-right: 30px;
    }
    .expo-detail__tags-section & {
      li {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  .expo-detail__social-link {
    a {
      font-size: rem(18px);
    }
  }
}

@include media-min(rem(750px)) {
  .expo-detail__social {
    @include grid-container;
  }
  .expo-detail__social-part {
    @include grid-item(1/2);
  }
}

@include media-min($about-section-columns-breakpoint) {
  .expo-detail__about-inner {
    @include grid-container;
  }
  .about__content,
  .about__team {
    @include grid-item(1/2);

    margin-bottom: 0;
  }
}

@include media-min(rem(1400px)) {
  .expo-detail__section-program-inner {
    @include grid-container;
  }
  .expo-detail__program {
    @include grid-item(2/5);

    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 1;
  }
  .expo-detail__social {
    @include grid-item(3/5);
  }
}

@include media-min(rem(1600px)) {
  .expo-detail__program,
  .expo-detail__social {
    @include grid-item-size(1/2);
  }
}
