/* -Link block
---------------------------------------------------------------------------- */
p.link-block {
  margin-bottom: 0;
}

.link-block--button {
  a {
    @include button-secondary;
  }
}
