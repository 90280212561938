/* -Global print styles
---------------------------------------------------------------------------- */
@media print {
  /* Set the print page margin in millimeters */
  @page {
    margin: 11mm 9.5mm;
  }

  /* Force black on white for everything, remove shadows */
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  /* Links */
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: ' (' attr(href) ')';
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }

  /* Tables */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }

  /* Embedded */
  img {
    max-width: 100% !important;
  }

  /* Text elements */
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  blockquote {
    page-break-inside: avoid;
  }
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /* Hide unnecessary elements */
  form,
  nav,
  .site-header {
    display: none !important;
  }
}
