/* -Icons
-----------------------------------------------------------------------------*/
/* AUTOGENERATED, DO NOT EDIT MANUALLY */

$icon-base-width: 16px;
$icon-base-height: 16px;
.icon {
  width: $icon-base-width;
  height: $icon-base-height;
  flex-grow: 0;
  flex-shrink: 0;
}

$icon-chevron-down-width: 22px;
$icon-chevron-down-height: 22px;
.icon--chevron-down {
  width: $icon-chevron-down-width;
  height: $icon-chevron-down-height;
}

$icon-chevron-right-thin-width: 22px;
$icon-chevron-right-thin-height: 22px;
.icon--chevron-right-thin {
  width: $icon-chevron-right-thin-width;
  height: $icon-chevron-right-thin-height;
}

$icon-chevron-right-width: 22px;
$icon-chevron-right-height: 22px;
.icon--chevron-right {
  width: $icon-chevron-right-width;
  height: $icon-chevron-right-height;
}

$icon-cross-width: 22px;
$icon-cross-height: 22px;
.icon--cross {
  width: $icon-cross-width;
  height: $icon-cross-height;
}

$icon-pause-width: 22px;
$icon-pause-height: 22px;
.icon--pause {
  width: $icon-pause-width;
  height: $icon-pause-height;
}

$icon-play-circle-width: 150px;
$icon-play-circle-height: 150px;
.icon--play-circle {
  width: $icon-play-circle-width;
  height: $icon-play-circle-height;
}

$icon-play-width: 22px;
$icon-play-height: 22px;
.icon--play {
  width: $icon-play-width;
  height: $icon-play-height;
}

$icon-discord-width: 30px;
$icon-discord-height: 30px;
.icon--discord {
  width: $icon-discord-width;
  height: $icon-discord-height;
}

$icon-facebook-width: 24px;
$icon-facebook-height: 24px;
.icon--facebook {
  width: $icon-facebook-width;
  height: $icon-facebook-height;
}

$icon-linkedin-width: 24px;
$icon-linkedin-height: 24px;
.icon--linkedin {
  width: $icon-linkedin-width;
  height: $icon-linkedin-height;
}

$icon-twitch-width: 30px;
$icon-twitch-height: 30px;
.icon--twitch {
  width: $icon-twitch-width;
  height: $icon-twitch-height;
}

$icon-twitter-width: 24px;
$icon-twitter-height: 24px;
.icon--twitter {
  width: $icon-twitter-width;
  height: $icon-twitter-height;
}