/* -Expo listing pages, regular and filtered
---------------------------------------------------------------------------- */

// Keep in sync with list item template.
$expo-list-image-width: 300px;
$expo-list-image-height: 180px;

.expo-list-content {
  @include wrap-wide;
  @include section-margin;
}

.expo-list-title {
  a {
    margin-left: 1em;
    font-family: $font-stack-main;
    font-size: rem(14px);
    font-weight: normal;
    text-decoration: underline !important;
    vertical-align: middle;

    &:hover,
    &:focus {
      text-decoration: none !important;
    }
  }
}

.expo-list-slider {
  @include section-margin-bottom;

  .flickity-prev-next-button {
    top: 0;
    transform: none;
    height: $expo-list-image-height;
  }
}

.expo-list-grid {
  @include grid-container(
    $expo-list-item-spacing,
    $expo-list-item-spacing-small
  );
  @include grid-pull-bottom(
    $expo-list-item-spacing,
    $expo-list-item-spacing-small
  );
}
.expo-list-grid__item {
  @include grid-item(1, $expo-list-item-spacing, $expo-list-item-spacing-small);
  @include grid-margin-bottom(
    $expo-list-item-spacing,
    $expo-list-item-spacing-small
  );

  @include media-min(rem(600px)) {
    @include grid-item-size(1 / 2);
  }
  @include media-min(rem(800px)) {
    @include grid-item-size(1 / 3);
  }
  @include media-min(rem(1200px)) {
    @include grid-item-size(1 / 4);
  }
}
.expo-list-grid__empty {
  @include grid-item(1, $expo-list-item-spacing, $expo-list-item-spacing-small);
}

/* ------------------- A single item/block ------------------- */
.expo-list-item {
  position: relative;
  font-size: rem(12px);

  .overlay-link {
    &:hover,
    &:focus {
      ~ .expo-list-item__image {
        opacity: 0.7;
      }
      ~ .expo-list-item__title {
        text-decoration: underline;
      }
    }
  }
  .expo-list-slider & {
    width: $expo-list-image-width;
  }
  .placeholder {
    @include aspect-ratio('5:3');

    background: #000;
  }
  p {
    margin: 0;
  }
  time {
    margin-right: 0.25em;
    font-weight: $font-weight-bold;
  }
}

.expo-list-item__image {
  transition: opacity 0.15s ease;
}

.expo-list-item__title {
  margin: 10px 0 2px;
  font: inherit;
}
