// Inspired by medium.com's approach. Define all z-index values here to get an
// easy overview of all the layers. Exceptions can be made if it's just local,
// like adding z-index: 1 just to place it above siblings.

// Z-Index Scale (private vars)
// --------------------------------------------------
$_z-index-1: 100;
$_z-index-2: 200;
$_z-index-3: 300;
$_z-index-4: 400;
$_z-index-5: 500;
$_z-index-6: 600;
$_z-index-7: 700;
$_z-index-8: 800;
$_z-index-9: 900;
$_z-index-10: 1000;

// Z-Index Applications
// --------------------------------------------------
$z-intro-image: $_z-index-1;
$z-intro-overlay: $z-intro-image + 5;
$z-intro-content: $z-intro-image + 10;
$z-content-after-intro: $z-intro-image + 15;
$z-header: $z-intro-image + 20;

$z-overlay-link: $_z-index-2;

$z-filter-dropdown: $_z-index-3;

$z-stream-overlay: $_z-index-7;
$z-stream-bar: $_z-index-7 + 2;
$z-stream-overlay-content: $_z-index-7 + 1;

$z-skiplink: $_z-index-8;
