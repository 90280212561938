/* -Conference stream overlay
---------------------------------------------------------------------------- */
$stream-overlay-spacing: 20px;
$stream-overlay-spacing-large: 40px;

.js body.has-stream-overlay {
  padding-top: $stream-bar-height;
}
.stream-overlay-open {
  &,
  body {
    overflow: hidden;
  }
}

.stream-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $stream-bar-height;
  z-index: $z-stream-overlay;
}

/* -The always visible bar
---------------------------------------------------------------------------- */
$color-stream-bar-separator: lighten($color-stream-bar-background, 10%);
$stream-bar-background-darkening: 5%;

.stream-bar {
  position: relative;
  display: flex;
  height: $stream-bar-height;
  background: $color-stream-bar-background;
  border-bottom: 1px solid $color-stream-bar-separator;
  font-size: rem(14px);
  z-index: $z-stream-bar;

  button {
    height: auto;
    font: inherit;
    border-radius: 0;
    z-index: 5;
  }
}

.stream-bar__live-status {
  display: flex;
}

.stream-bar__text {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;

  // Place the actual text above the pseudo elements
  > span {
    position: relative;
    z-index: 1;
    white-space: nowrap;
  }
}
.stream-bar__text--title,
.stream-bar__text--live {
  padding-right: 10px;
}
.stream-bar__text--live,
.stream-bar__text--current-event {
  padding-left: 25px;
}
.stream-bar__text--title {
  z-index: 4;
}
.stream-bar__text--live {
  z-index: 3;
}
.stream-bar__text--current-event {
  z-index: 2;
  min-width: 0;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.stream-bar__text--title,
.stream-bar__text--live {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    // Magic number, not sure about what math to use if any.
    right: rem(-8px);
    transform: skewX(15deg);
    border-right: 1px solid $color-stream-bar-separator;
    pointer-events: none;
  }
}
.stream-bar__text--title {
  &,
  &::after {
    background: darken(
      $color-stream-bar-background,
      $stream-bar-background-darkening * 2
    );
  }
}
.stream-bar__text--live {
  &,
  &::after {
    background: darken(
      $color-stream-bar-background,
      $stream-bar-background-darkening
    );
  }
}

@include media-max(rem(750px)) {
  .stream-bar .currently-playing-icon,
  .stream-bar__text--live,
  .stream-bar__text--current-event {
    display: none !important;
  }
}
@include media-max(rem(450px)) {
  .stream-bar__text--title {
    display: none !important;
  }
}

.stream-bar__play-pause {
  // Prevent shifting when the text changes
  min-width: rem(120px);
  background: #000;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background: lighten(#000, 15%);
    }
  }
  .icon {
    margin-right: 0.5em !important;
    color: $color-brand-magenta;
  }
  .icon--pause {
    display: none;
  }
  .stream-playing & {
    .icon--pause {
      display: inline-block;
    }
    .icon--play {
      display: none;
    }
  }
}

.stream-bar__toggle {
  // Prevent shifting when the text changes
  min-width: rem(120px);
  margin-left: auto;
  border-left: 1px solid $color-stream-bar-separator;
  background: transparent;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background: lighten($color-stream-bar-background, 10%);
    }
  }
  .icon {
    margin-left: 0.5em !important;
    color: $color-brand-orange;
  }
  .icon--cross {
    display: none;
  }
  .stream-overlay-open & {
    .icon--cross {
      display: inline-block;
    }
    .icon--chevron-down {
      display: none;
    }
  }
}

/* -Overlay content
---------------------------------------------------------------------------- */
$stream-toggle-animation-duration: 0.3s;
.stream-overlay__content-wrap {
  // For z-index
  position: relative;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  background: $color-body-background;
  transform: translateY(-100%);
  // Delay visibility and height so that they aren't put into effect before
  // the transform is finished transitioning.
  transition: transform $stream-toggle-animation-duration ease,
    visibility 0s $stream-toggle-animation-duration,
    height 0s $stream-toggle-animation-duration;
  z-index: $z-stream-overlay-content;

  .stream-overlay-open & {
    visibility: visible;
    overflow-y: auto;
    height: calc(100vh - #{$stream-bar-height});
    transform: translateY(0);
    // Remove the delay when opening.
    transition-delay: 0s;
  }
}

.stream-overlay__content {
  padding: $stream-overlay-spacing;

  // Using the small spacing vertically blends the sections together
  > div:not(:last-child) {
    margin-bottom: $stream-overlay-spacing-large;
  }
}

.stream-overlay__player-tablist {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  [role='tablist'] {
    margin-bottom: 0;
  }
  > div {
    flex-grow: 1;
  }
}
.stream-overlay__player-tablist-title {
  margin: 0 20px 0 0;
}

.stream-overlay__player {
  .responsive-object {
    margin: 0;
    background: #000;
  }
  .spinner {
    margin-right: 0.5em;
  }
  @include media-min(rem(600px)) {
    button[role='tab'] {
      min-width: 150px;
    }
  }
}
.stream-overlay__player-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 40rem;
  margin: 0;
  padding: 0 5%;
  text-align: center;
}

.stream-overlay__info {
  // Using the small spacing vertically blends the sections together
  > div + div {
    margin-top: $stream-overlay-spacing-large;
  }
}

@include media-min(rem(900px)) {
  .stream-overlay__content {
    @include grid-container($stream-overlay-spacing-large);

    padding: $stream-overlay-spacing-large;

    > div {
      @include grid-item-gutter-size($stream-overlay-spacing-large);
    }
  }
  .stream-overlay__player {
    @include grid-item-auto-size;
  }
  .stream-overlay__info {
    @include grid-item-size(350px);
  }
  .stream-overlay__partners,
  .stream-overlay__schedule {
    @include grid-item-size(1);
  }
}
