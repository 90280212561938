/* -Toggled tag filter links
---------------------------------------------------------------------------- */
$tag-select-padding-horizontal: 12px;
$tag-select-animaiton-duration: 0.15s;

.tag-filter {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.tag-filter-select {
  position: relative;
  display: inline-block;
  margin: 0 20px 10px 0;

  button {
    @include rounded('small');

    height: auto;
    padding: 7px $tag-select-padding-horizontal;
    border: 2px solid;
    background: transparent;
    color: inherit;
    font: inherit;
    text-transform: none;

    .text {
      display: inline-block;
      min-width: rem(100px);
      text-align: left;
    }
    .icon {
      transition: transform $tag-select-animaiton-duration ease;
    }
  }
  ul {
    @include rounded('small');

    visibility: hidden;
    overflow: hidden;
    // Safari buggily renders the list behind other elements while responding
    // to inputs as if it's still on top. Using translate3D probably forces
    // some other layer composition and makes it work.
    transform: translateY(-10px);
    transform: translate3D(0, -10px, 0);
    opacity: 0;

    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    margin: 5px 0 0;
    padding: 5px 0;
    background: #fff;
    color: $color-gray-dark;
    transition: transform $tag-select-animaiton-duration ease,
      opacity $tag-select-animaiton-duration ease,
      visibility 0s $tag-select-animaiton-duration;
    z-index: $z-filter-dropdown;
  }
  a {
    display: block;
    padding: 7px $tag-select-padding-horizontal;
    color: inherit;
    text-decoration: none;
    outline-color: $color-gray-dark !important;

    &:hover,
    &:focus {
      background: darken(#fff, 10%);
    }
  }
  a.active {
    background: darken(#fff, 15%);
  }
}
.tag-filter-select--open {
  ul {
    visibility: visible;
    // See above
    transform: translateY(0);
    transform: translate3D(0, 0, 0);
    opacity: 1;
    transition-delay: 0s;
  }
  button .icon {
    transform: rotate(-180deg);
  }
}
