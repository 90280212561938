/* -Generic navigation stuff
-----------------------------------------------------------------------------*/
%nav-bar,
%nav-list {
  &,
  & ul {
    list-style: none;
    margin: 0;
  }
}

%nav-bar {
  li {
    display: inline-block;
  }
  a {
    display: block;
    border: 0;
    position: relative;
    text-decoration: none;
  }
}

.nav-bar {
  @extend %nav-bar;
}

.nav-list {
  @extend %nav-list;
}
