/* -Text variations
-----------------------------------------------------------------------------*/
/* Larger and smaller text */
@mixin lead-text {
  @include type-size('lead');

  margin-bottom: 1.5rem;
  font-weight: $font-weight-light;
}
@mixin sub-text {
  @include type-size('sub');
}

.lead-text {
  @include lead-text;
}
.sub-text {
  @include sub-text;
}

.lead-text,
.sub-text {
  p,
  ol,
  ul {
    margin-bottom: inherit;
  }
}

// Inline text alongside things like icons
.text {
  vertical-align: middle;

  // Restore default alignment if it appears on its own
  &:only-child {
    vertical-align: baseline;
  }
}
