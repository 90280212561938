/* - Pin login page
-----------------------------------------------------------------------------*/
body.pin-login-page {
  padding-top: 0 !important;
}

.pin-login-header {
  @include section-padding(0.5);

  border-bottom: 1px solid $color-separator;
}
.pin-login-header__logo {
  max-width: 640px;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.block-content {
  .pin-login-page & {
    text-align: center;
  }
}

.pin-login-form {
  text-align: center;

  form {
    @include wrap-custom(rem(400px));
  }
  button,
  input {
    width: 100%;
    height: rem(70px);
    line-height: rem(70px);
    font-size: rem(24px);
    text-align: center;
  }
  // Include matching left padding to prevent text shifting. Not an issue in
  // regular fields since they have left aligned text.
  .form-field--error input {
    padding-left: $form-field-padding + em(25px);
    padding-right: $form-field-padding + em(25px);
  }
}

@include media-min(rem(1300px)) {
  .pin-login-form {
    form {
      @include wrap-width-custom(rem(550px));
    }
    input,
    button {
      height: rem(100px);
      line-height: rem(100px);
      font-size: rem(40px);
    }
  }
}
