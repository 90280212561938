/* -Buttons in stream-specific colors
---------------------------------------------------------------------------- */
// NOTE! Overly qualified selectors since these styles may be used for tabs and
// the tab styles use a button[role='tab'] selector. Would normally not specify
// the button element here.
// The same is true for the overuse of !important for some properties.

button.stream-button {
  padding: 2px 0.75em;
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-radius: 30px !important;
  font-family: $font-stack-main;
  font-weight: normal;
  font-size: rem(14px);
  line-height: inherit;
  text-transform: none;
  white-space: nowrap;
  background: transparent !important;
  color: inherit !important;

  .program-event-row--passed & {
    border-color: rgba(255, 255, 255, 0.35);
  }
  &:hover,
  &:focus,
  &[aria-selected='true'] {
    background: rgba(255, 255, 255, 0.15) !important;
  }
  &[role='tab'] + &[role='tab'] {
    margin-left: 10px;
    border-left-width: 1px;
  }
}

button.stream-button--small {
  padding: 0 em(7px, 12px);
  font-size: rem(12px);
}

button.stream-button--1 {
  border-color: $color-brand-magenta;

  .program-event-row--passed & {
    border-color: shade($color-brand-magenta, 20%);
  }
  &:hover,
  &:focus,
  &[aria-selected='true'] {
    background: shade($color-brand-magenta, 60%) !important;
  }
}
button.stream-button--2 {
  border-color: $color-brand-orange;

  .program-event-row--passed & {
    border-color: shade($color-brand-orange, 20%);
  }
  &:hover,
  &:focus,
  &[aria-selected='true'] {
    background: shade($color-brand-orange, 60%) !important;
  }
}
button.stream-button--3 {
  border-color: $color-blue;

  .program-event-row--passed & {
    border-color: shade($color-blue, 20%);
  }
  &:hover,
  &:focus,
  &[aria-selected='true'] {
    background: shade($color-blue, 50%) !important;
  }
}
// Just keep the default, colorless border
// button.stream-button--4 {}
