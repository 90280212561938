/* -Intro image with content
---------------------------------------------------------------------------- */

// Keep duration in sync with Intro.js
$intro-animation-duration: 6000ms;

/* $intro-animation-pan: 2%;
$intro-animation-scale: 1.1;
$initial-intro-transform: translate(0, 0) scale(1);
@keyframes intro-top-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-top-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
} */

// Must have a wrapping column flex container for IE11
// https://github.com/philipwalton/flexbugs#flexbug-3
.intro {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.intro__inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 40vh;
  max-height: 250px;

  @include media-min(rem(700px)) {
    max-height: 300px;
  }
  @include media-min(rem(1000px)) {
    max-height: 350px;
  }

  .intro--large & {
    height: auto;
    min-height: 60vh;
    max-height: none;

    @media screen and (orientation: landscape) and (min-width: rem(1000px)) {
      height: 70vh;
      max-height: 800px;
    }
  }
}

// Gradient overlays
.intro::before,
.intro__inner::before,
.intro__inner::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: $z-intro-overlay;
  pointer-events: none;
}
.intro::before {
  @include easing-gradient('to bottom', lighten($color-body-background, 5%));

  top: 0;
  height: 200px;
}
.intro__inner {
  &::before,
  &::after {
    bottom: 0;
  }
  &::after {
    @include easing-gradient('to top', $color-body-background);

    height: 100%;
  }
  // Add a bit of solid background before the gradient when there is content.
  .intro--has-content & {
    &::before {
      height: 40px;
      background: $color-body-background;
    }
    &::after {
      bottom: 40px;
    }
  }
}

.intro__bg {
  background-color: lighten($color-body-background, 10%);
  // Prevent dragging the image when trying to select text.
  pointer-events: none;

  &,
  picture {
    // Need to override flickity base styles.
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-intro-image;
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // Ensure the first element is on top to prevent flashing when Flickity is
  // initiated, since it sets the first slide as selected. This will only
  // target non-Flickity picture since wrapping divs are added for the slider.
  > picture:first-child {
    z-index: $z-intro-image + 1;
  }
}
/* .intro-image-active {
  // Add buffer to keep the animation going as the active slide changes
  animation-duration: $intro-animation-duration + 2000ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  &[data-direction='top-left'] {
    animation-name: intro-top-left;
  }
  &[data-direction='top-right'] {
    animation-name: intro-top-right;
  }
  &[data-direction='bottom-right'] {
    animation-name: intro-bottom-right;
  }
  &[data-direction='bottom-left'] {
    animation-name: intro-bottom-left;
  }
} */

.intro__content {
  position: relative;
  width: 100%;
  // Prevent any content from going under the header. Magic number.
  margin-top: 11rem;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: $z-intro-content;

  p,
  .rich-text {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.intro--no-content + * {
  @include section-pull-top;

  position: relative;
  z-index: $z-content-after-intro;
}
