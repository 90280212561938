/* -Aria tabs
---------------------------------------------------------------------------- */
[role='tablist'] {
  display: flex;
  margin-bottom: 10px;
}
// Force tabs to be buttons
button[role='tab'] {
  height: 2em;
  line-height: 2em;
  padding: 1px 1em 0;
  border: 1px solid $color-body-foreground;
  border-radius: 0;
  background: transparent;
  color: $color-body-foreground;
  font-size: rem(14px);
  letter-spacing: 0.5px;

  & + & {
    border-left-width: 0;
  }
  &:hover,
  &:focus,
  &[aria-selected='true'] {
    background: $color-body-foreground;
    color: $color-body-background;
  }
  &:first-child {
    border-top-left-radius: $program-schedule-item-date-radius;
    border-bottom-left-radius: $program-schedule-item-date-radius;
  }
  &:last-child {
    border-top-right-radius: $program-schedule-item-date-radius;
    border-bottom-right-radius: $program-schedule-item-date-radius;
  }
}
